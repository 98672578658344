import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {
  appVersion = '1.2.3';

  constructor() { }
}
